import React from 'react';
import styled from 'styled-components';

const FlowerDividerWrapper = styled.div`
  transform: translateY(-45%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  z-index: -1;
`;

const Svg = styled.svg`
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
`;

const Path = styled.path`
  fill: hsl(var(--color-red));
`;

export default function FlowerDivider({ fill, rotate, translate }) {
  return (
    <>
      <FlowerDividerWrapper rotate={rotate} translate={translate}>
        <Svg
          version='1.1'
          id='Layer_1'
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 1215.09 173.59'
        >
          <Path
            fillColor={fill}
            d='M1215.09,81.26c0-0.3-0.25-0.55-0.55-0.55c0,0-61.18,1.87-81.45-0.08c-15.83-1.52-20.03-4.68-20.03-4.68
	c-0.57-0.32-0.97-0.87-0.99-1.52c0,0,0-0.01,0-0.01c-0.24-8.1-0.69-23.19-16.99-35.86c-8.9-6.92-22.19-9.18-33.86-5.77
	c-4.26,1.25-8.24,3.17-11.88,5.74c-0.28,0.2-0.67,0.1-0.82-0.22c-2.35-5.07-5.68-9.55-9.81-13.14c-8.03-6.98-19.06-10.47-28.78-9.1
	c-19.82,2.79-27.77,15.72-31.59,21.93l-0.04,0.06c-0.05,0.08-0.1,0.17-0.16,0.26c-0.13-0.01-0.25-0.03-0.37-0.04
	c-7.25-0.79-22.33-2.44-36.69,11.51c-6.59,6.4-10.52,16.18-10.52,26.16c0,1.39,0.08,2.77,0.22,4.14c0.04,0.33-0.22,0.61-0.54,0.61
	h-11.9c-0.23,0-0.42-0.14-0.51-0.35c-0.62-1.56-1.57-3.04-2.83-4.33c-0.53-0.54-1.41-1.34-2.52-2.29c-0.41-0.35-0.13-1.03,0.42-0.98
	c1.45,0.14,2.64,0.23,3.4,0.24c3.46,0.03,6.8-1.28,9.17-3.6c2.18-2.13,3.39-4.93,3.42-7.88c0.03-3.18-1.35-6.27-3.8-8.47
	c-2.3-2.07-5.34-3.23-8.55-3.27c-0.76-0.01-1.94,0.05-3.4,0.17c-0.54,0.04-0.82-0.64-0.4-0.99c1.13-0.93,2.03-1.71,2.57-2.23
	c2.37-2.32,3.76-5.36,3.91-8.57c0.16-3.23-0.95-6.23-3.13-8.45c-2.06-2.1-4.87-3.28-7.92-3.31c-3.33-0.03-6.65,1.33-9.12,3.74
	c-0.54,0.53-1.34,1.41-2.29,2.52c-0.35,0.41-1.03,0.13-0.98-0.42c0.15-1.45,0.23-2.64,0.24-3.4c0.03-3.21-1.06-6.27-3.09-8.62
	c-2.15-2.49-5.21-3.94-8.39-3.97c-2.95-0.03-5.77,1.13-7.95,3.26c-2.37,2.33-3.76,5.64-3.79,9.1c-0.01,0.76,0.05,1.95,0.17,3.4
	c0.04,0.54-0.64,0.82-0.99,0.4c-0.93-1.13-1.7-2.03-2.24-2.57c-2.41-2.46-5.7-3.89-9.03-3.93c-3.05-0.03-5.89,1.09-7.99,3.14
	c-4.57,4.48-4.38,12.12,0.43,17.04c0.53,0.54,1.41,1.34,2.52,2.29c0.41,0.35,0.13,1.03-0.42,0.98c-1.45-0.15-2.64-0.23-3.4-0.24
	c-3.45-0.04-6.8,1.28-9.17,3.6c-0.85,0.83-1.55,1.77-2.1,2.78c-0.24,0.44-0.89,0.37-1-0.12c-0.53-2.27-1.59-5.86-3.42-9.3
	c-2.33-4.53-5.96-8.79-10.2-11.08c-9.52-5.43-17.63-5.07-28.04-6.74c-0.46-0.07-0.8,0.43-0.55,0.83c5.86,9.48,7.37,17.9,15.78,25.01
	c6.94,5.83,20.83,3.73,25.93,3.04c0.38-0.05,0.68,0.3,0.58,0.67c-0.25,0.9-0.38,1.83-0.39,2.78c-0.03,3.18,1.35,6.27,3.8,8.47
	c2.3,2.07,5.34,3.23,8.55,3.27c0.76,0.01,1.94-0.05,3.4-0.17c0.54-0.04,0.82,0.64,0.4,0.99c-1.13,0.93-2.03,1.7-2.57,2.23
	c-1.48,1.45-2.54,3.15-3.18,4.95c-0.08,0.22-0.28,0.37-0.51,0.37h-24.93c-2.11,0-4.07-1.05-5.25-2.79l-0.02-0.03
	c-0.85-1.24-2.03-2.19-3.44-2.73c-0.87-0.34-1.78-0.52-2.71-0.52c-2.03,0-4,0.84-5.41,2.3c-0.73,0.74-1.33,1.64-1.79,2.67
	c0.01,0,0.01,0,0.02,0c-0.28-0.01-0.56-0.02-0.84-0.02c-0.28,0-0.57,0.01-0.85,0.02c0,0,0.01,0,0.01,0
	c-0.46-1.03-1.05-1.93-1.78-2.66c-1.41-1.47-3.38-2.31-5.42-2.31c-0.93,0-1.85,0.17-2.71,0.52c-2.29,0.89-3.94,2.83-4.52,5.15
	c-0.06,0.25-0.29,0.41-0.54,0.41l-34.95,0c-0.53,0-1.03-0.28-1.3-0.74l0-0.01c-3.06-5.16-6.03-9.61-8.85-13.44
	c-0.17-0.23-0.12-0.56,0.11-0.73c4.04-2.94,16.49-12.01,19.05-23.3c3.04-13.32-0.48-22.25-0.18-34.37c0.01-0.5-0.59-0.76-0.94-0.41
	c-9.29,9.24-18.29,14.06-23.7,26.64c-3.61,8.44,0.02,19.9,2.9,26.81c0.24,0.58-0.53,1.04-0.93,0.55c-4-4.97-7.55-8.59-10.37-11.15
	c-0.09-0.08-0.14-0.19-0.15-0.31c-0.14-2.76-0.68-8.09-2.47-13.58c-1.98-6.35-5.63-12.91-10.4-17.33
	C706.13,9.01,696.05,6.25,683.49,0.06c-0.45-0.22-0.94,0.23-0.75,0.69c5.43,13.77,5.62,24.5,14.62,36.41
	c6.49,8.53,20.7,11.88,28.44,13.39c0.08,0.02,0.15,0.05,0.22,0.11c2.93,2.67,7.22,6.95,12.16,12.99c0.37,0.45-0.12,1.08-0.65,0.84
	c-6.75-3.12-18.39-7.36-27.11-3.95c-11.41,4.48-16.7,12.03-24.51,20c-0.1,0.11-0.25,0.17-0.39,0.17l-5.98,0
	c-0.11,0-0.21-0.03-0.3-0.09c-3.12-2.06-6.09-3.49-8.21-4.51c-0.27-0.13-0.62-0.3-0.99-0.48c-0.18-0.09-0.3-0.28-0.31-0.48
	c-0.01-0.27-0.02-0.52-0.02-0.73c-0.24-8.1-0.69-23.19-16.99-35.86c-8.9-6.92-22.19-9.18-33.86-5.77
	c-4.26,1.25-8.24,3.17-11.88,5.74c-0.28,0.2-0.67,0.1-0.82-0.22c-2.35-5.07-5.68-9.55-9.81-13.14c-8.03-6.98-19.06-10.47-28.78-9.1
	c-19.82,2.79-27.77,15.72-31.59,21.93l-0.04,0.06c-0.05,0.08-0.1,0.17-0.16,0.26c-0.13-0.01-0.25-0.03-0.37-0.04
	c-7.25-0.79-22.34-2.44-36.69,11.51c-6.59,6.4-10.52,16.18-10.52,26.16c0,1.39,0.08,2.77,0.22,4.14c0.04,0.33-0.22,0.61-0.54,0.61
	h-11.9c-0.23,0-0.42-0.14-0.51-0.35c-0.62-1.56-1.57-3.04-2.83-4.33c-0.53-0.54-1.41-1.34-2.52-2.29c-0.41-0.35-0.13-1.03,0.42-0.98
	c1.45,0.15,2.64,0.23,3.4,0.24c3.46,0.03,6.8-1.28,9.17-3.6c2.18-2.13,3.39-4.93,3.42-7.88c0.03-3.18-1.35-6.27-3.8-8.47
	c-2.3-2.07-5.34-3.23-8.55-3.27c-0.76-0.01-1.94,0.05-3.4,0.17c-0.54,0.04-0.82-0.64-0.4-0.99c1.13-0.93,2.03-1.71,2.57-2.23
	c2.37-2.32,3.76-5.36,3.91-8.57c0.16-3.23-0.95-6.23-3.13-8.45c-2.06-2.1-4.87-3.28-7.92-3.31c-3.33-0.03-6.65,1.33-9.11,3.74
	c-0.54,0.53-1.34,1.41-2.29,2.52c-0.35,0.41-1.03,0.13-0.98-0.42c0.15-1.45,0.23-2.64,0.24-3.4c0.03-3.21-1.07-6.27-3.09-8.62
	c-2.15-2.49-5.21-3.94-8.39-3.97c-2.95-0.03-5.77,1.13-7.95,3.26c-2.37,2.33-3.75,5.64-3.79,9.1c-0.01,0.76,0.05,1.95,0.17,3.4
	c0.04,0.54-0.64,0.82-0.99,0.4c-0.93-1.13-1.7-2.03-2.23-2.57c-2.41-2.46-5.7-3.89-9.03-3.93c-3.05-0.03-5.89,1.09-7.99,3.14
	c-4.57,4.48-4.38,12.12,0.43,17.04c0.53,0.54,1.41,1.34,2.52,2.29c0.41,0.35,0.13,1.03-0.42,0.98c-1.45-0.15-2.64-0.23-3.4-0.24
	c-3.46-0.04-6.8,1.28-9.17,3.6c-0.85,0.83-1.55,1.77-2.1,2.78c-0.24,0.44-0.89,0.37-1-0.12c-0.53-2.27-1.59-5.86-3.42-9.3
	c-2.33-4.53-5.96-8.79-10.2-11.08c-9.52-5.43-17.63-5.07-28.04-6.74c-0.46-0.07-0.8,0.43-0.55,0.83c5.86,9.48,7.37,17.9,15.78,25.01
	c6.94,5.83,20.83,3.73,25.93,3.04c0.38-0.05,0.68,0.3,0.58,0.67c-0.25,0.9-0.38,1.83-0.39,2.78c-0.03,3.18,1.35,6.27,3.8,8.47
	c0.13,0.12,0.26,0.23,0.39,0.34c-1.71,0.85-2.87,2.45-2.89,4.31c-0.03,2.77,2.49,5.04,5.62,5.07c0.61,0.01,1.8-0.07,3.32-0.21
	c0.42-0.04,0.72,0.4,0.55,0.78c-0.15,0.32-0.28,0.65-0.4,0.98c-0.08,0.22-0.28,0.37-0.51,0.37c-2.59,0-17.57,0-24.95,0
	c-2.1,0-4.05-1.04-5.23-2.78l-0.03-0.04c-0.85-1.24-2.03-2.19-3.44-2.73c-0.87-0.34-1.78-0.52-2.71-0.52c-2.03,0-4,0.84-5.41,2.3
	c-0.73,0.74-1.33,1.64-1.79,2.67c0.01,0,0.01,0,0.02,0c-0.28-0.01-0.56-0.02-0.84-0.02c-0.28,0-0.57,0.01-0.85,0.02
	c0,0,0.01,0,0.01,0c-0.46-1.03-1.05-1.93-1.78-2.66c-1.41-1.47-3.38-2.31-5.42-2.31c-0.93,0-1.85,0.17-2.71,0.52
	c-1.08,0.42-2.01,1.07-2.76,1.88c-2.1,2.28-4.99,3.68-8.09,3.68l-29.71,0c-0.2,0-0.38-0.1-0.47-0.27c-0.09-0.16-0.19-0.32-0.28-0.47
	c-3.06-5.16-6.03-9.61-8.85-13.44c-0.17-0.23-0.12-0.56,0.11-0.73c4.04-2.94,16.49-12.01,19.05-23.3
	c3.04-13.32-0.48-22.25-0.18-34.37c0.01-0.5-0.59-0.76-0.94-0.41c-9.29,9.24-18.29,14.06-23.7,26.64c-3.61,8.44,0.02,19.9,2.9,26.81
	c0.24,0.58-0.53,1.04-0.93,0.55c-4-4.97-7.55-8.59-10.37-11.15c-0.09-0.08-0.14-0.19-0.15-0.31c-0.14-2.76-0.68-8.09-2.47-13.58
	c-1.98-6.35-5.63-12.91-10.4-17.33C263.76,9.01,253.68,6.25,241.12,0.06c-0.45-0.22-0.94,0.23-0.75,0.69
	c5.43,13.77,5.62,24.5,14.62,36.41c6.49,8.53,20.7,11.88,28.44,13.39c0.08,0.02,0.15,0.05,0.22,0.11
	c2.93,2.67,7.22,6.95,12.16,12.99c0.37,0.45-0.12,1.08-0.65,0.84c-6.75-3.12-18.39-7.36-27.11-3.95
	c-11.4,4.47-16.7,12.02-24.51,19.99c-0.11,0.11-0.26,0.17-0.41,0.17h0c-3.73,0-7.4-0.95-10.69-2.7c-1.42-0.76-2.71-1.38-3.79-1.9
	c-0.1-0.05-0.21-0.1-0.32-0.16c-0.59-0.28-0.97-0.87-1-1.52l0-0.01c-0.24-8.1-0.69-23.19-16.99-35.86
	c-8.9-6.92-22.19-9.18-33.86-5.77c-4.26,1.25-8.24,3.17-11.88,5.74c-0.28,0.2-0.67,0.1-0.82-0.22c-2.35-5.07-5.68-9.56-9.81-13.14
	c-8.03-6.98-19.06-10.47-28.78-9.1c-19.82,2.79-27.77,15.72-31.59,21.93l-0.04,0.06c-0.05,0.08-0.1,0.17-0.16,0.26
	c-0.13-0.01-0.25-0.03-0.37-0.04c-7.25-0.79-22.34-2.44-36.69,11.51c-6.59,6.4-10.52,16.18-10.52,26.16l0,0.05
	c0,2.58-2.11,4.7-4.69,4.7H0.55c-0.3,0-0.55,0.25-0.55,0.55v91.78c0,0.3,0.25,0.55,0.55,0.55h1213.99 M741.79,68.67
	c2.59,3.42,5.3,7.29,8.04,11.62c0.23,0.36-0.03,0.84-0.46,0.84l-17.89,0c-0.98,0-1.42-1.23-0.67-1.85
	c4.56-3.75,8.14-8.09,10.13-10.62C741.15,68.38,741.57,68.39,741.79,68.67z M665.75,80.1l0.02,0.01c0.5,0.27,0.31,1.02-0.26,1.02
	h-0.66c-0.54,0-0.75-0.69-0.32-1l0.27-0.19c0.17-0.12,0.39-0.13,0.57-0.04L665.75,80.1z M641.84,80.54c0.33,0,0.64,0.13,0.88,0.37
	c0.07,0.07,0.13,0.15,0.18,0.23h-2.17l0.23-0.23C641.2,80.67,641.51,80.54,641.84,80.54z M517.34,80.9
	c0.24-0.24,0.55-0.37,0.88-0.37c0.33,0,0.64,0.13,0.88,0.37l0.23,0.23h-2.17C517.21,81.05,517.27,80.97,517.34,80.9z M299.42,68.67
	c2.59,3.42,5.3,7.29,8.04,11.62c0.23,0.36-0.03,0.84-0.46,0.84l-13.65,0c-2.4,0-3.57-2.93-1.84-4.59c3.1-2.97,5.54-5.95,7.06-7.89
	C298.78,68.38,299.2,68.39,299.42,68.67z'
          />
        </Svg>
      </FlowerDividerWrapper>
    </>
  );
}
