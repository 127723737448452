import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { useWindowSize } from 'react-use';
import { ContainerStyles } from '../styles/ContainerStyles';
import Logo from './Logo';
import HamburgerMenu from './HamurgerMenu';
import { QUERIES } from '../constants';

const menuItems = [
  { name: 'News', path: '/news', color: 'hsl(var(--color-red))' },
  { name: 'Events', path: '/events', color: 'var(--color-matyo-orange)' },
  { name: 'Artists', path: '/artists', color: 'var(--color-matyo-purple)' },
  { name: 'Contact', path: '/contact', color: 'var(--color-matyo-blue)' },
];

const HeaderStyles = styled.header`
  background-color: ${(p) => p.styles.backgroundColor};
  color: ${(p) => (p.pathname === '/' ? 'var(--color-white)' : p.styles.color)};
  /* padding: ${(p) => (p.width < 917 ? '15px 0' : '10px 0')}; */
  padding: 15px 0;
  box-shadow: ${(p) => p.styles.boxShadow};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transition: all 450ms ease-in-out;
  z-index: 9997;

  a {
    text-decoration: none;
    color: inherit;
  }

  @media ${QUERIES.mobileAndDown} {
    background-color: ${(p) =>
      p.pathname === '/' ? p.styles.backgroundColor : 'hsl(var(--color-red))'};
    color: var(--color-white);
  }
`;

const HeaderContentWrapper = styled.div`
  text-transform: uppercase;
  font-family: var(--font-family);
  font-weight: 700;
  display: flex;
  align-items: center;

  @media (max-width: 916px) {
    justify-content: center;
  }
`;

const MenuItems = styled.ul`
  font-family: var(--font-family);
  font-weight: 400;
  display: flex;
  gap: 60px;
  margin-left: auto;

  a {
    letter-spacing: 1px;
  }

  a[aria-current='page'] {
    font-family: var(--font-family);
    border-bottom: 2px solid;
    letter-spacing: 0;
    font-weight: 700;
  }

  @media (max-width: 916px) {
    display: none;
  }
`;

const MenuItem = styled.li`
  padding: 0 2px;
  /* test */
  color: ${(p) =>
    p.scrolled || p.pathname === '/' ? `var(--color-white)` : p.color};
`;

const MobileMenu = styled.div`
  background-color: hsl(var(--color-red));
  position: fixed;
  left: 0;
  top: 0;
  width: 80%;
  bottom: 0;
  transform: ${(p) => (!p.isOpen ? 'translateX(-110%)' : 'translateX(0)')};
  transition: all 0.3s ease-in-out;
  text-align: center;
  z-index: 9998;
`;

const MobilMenuBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  opacity: ${(p) => (p.isOpen ? 1 : 0)};
  display: ${(p) => (p.isOpen ? 'block' : 'none')};
  transition: opacity 350ms ease-in-out;
`;

const LogoLink = styled(Link)`
  display: block;
  border-bottom: 2px solid var(--color-white);
  padding-bottom: 0.5rem;
  border-radius: 8px;
`;

const MobileMenuList = styled.ul`
  display: flex;
  flex-direction: column;

  a {
    margin-bottom: 2rem;
    letter-spacing: 2px;
  }

  a[aria-current='page'] {
    display: inline-block;
    font-weight: 700;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 24px;
    color: hsl(var(--color-red));
  }

  li {
    padding: 5px 10px;
    border-radius: 24px;
  }

  /* Don't show hover state on touch devices */
  @media (hover: hover) and (pointer: fine) {
    li:hover {
      background-color: rgba(255, 255, 255, 0.3);
    }
  }
`;

export default function Header({ location }) {
  const { width } = useWindowSize();
  const { pathname } = location;
  const [isOpen, setIsOpen] = useState(false);
  const [headerStyles, setHeaderStyles] = useState({
    backgroundColor: 'transparent',
    color: 'hsl(var(--color-red))',
    boxShadow: 'none',
    scrolled: false,
  });

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  function handleScroll() {
    const currentScroll = window.scrollY;
    if (currentScroll > 24) {
      setHeaderStyles({
        ...headerStyles,
        backgroundColor: 'hsl(var(--color-red))',
        color: 'var(--color-white)',
        boxShadow: '0 1px 3px rgba(57, 63, 72, 0.2);',
        scrolled: true,
      });
    } else {
      setHeaderStyles({
        ...headerStyles,
        backgroundColor: 'transparent',
        color: 'hsl(var(--color-red))',
        boxShadow: 'none',
        scrolled: false,
      });
    }
  }

  return (
    <>
      <HeaderStyles styles={headerStyles} pathname={pathname} width={width}>
        <ContainerStyles>
          <HeaderContentWrapper>
            <Logo
              fill={
                pathname === '/' ? 'var(--color-white)' : headerStyles.color
              }
            />
            <MenuItems>
              {menuItems.map((item, i) => (
                <MenuItem
                  key={i}
                  pathname={pathname}
                  scrolled={headerStyles.scrolled}
                  color='hsl(var(--color-red))'
                >
                  <Link to={item.path}>{item.name}</Link>
                </MenuItem>
              ))}
            </MenuItems>
            {width < 917 && (
              <HamburgerMenu
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                color={'var(--color-white)'}
              />
            )}
          </HeaderContentWrapper>
        </ContainerStyles>
      </HeaderStyles>
      <MobilMenuBackdrop isOpen={isOpen} onClick={() => setIsOpen(false)} />
      <MobileMenu isOpen={isOpen}>
        <div
          style={{ padding: 30, color: 'var(--color-white)', height: '100%' }}
        >
          <LogoLink
            aria-label='link to homepage'
            to={`/`}
            style={{ color: 'inherit', textDecoration: 'none' }}
            onClick={() => setIsOpen(false)}
          >
            {/* Gallus &amp; Hirundo */}
            <div>
              <svg
                id='Layer_1'
                data-name='Layer 1'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 587.95 72.43'
                fill='var(--color-white)'
              >
                <path d='M78.41,68.31a1.1,1.1,0,0,1-1-.67L74.16,57.37H58.06L54.92,67.64a1.15,1.15,0,0,1-1,.67h-8a.5.5,0,0,1-.49-.67L59.84,26a1.08,1.08,0,0,1,1-.68H72a1.1,1.1,0,0,1,1,.68L87.07,67.64a.51.51,0,0,1-.49.67ZM71.83,49.93l-5.9-18.37-5.6,18.37Z' />
                <path d='M90.41,68.31a.79.79,0,0,1-.73-.74l.43-20.77L89.68,26a.8.8,0,0,1,.73-.74h8.18a.8.8,0,0,1,.74.74L99,46.24l.37,15.12h17.21a.79.79,0,0,1,.73.74v5.47a.79.79,0,0,1-.73.74Z' />
                <path d='M120.91,68.31a.79.79,0,0,1-.73-.74l.43-20.77L120.18,26a.8.8,0,0,1,.73-.74h8.18a.79.79,0,0,1,.73.74l-.36,20.22.36,15.12H147a.79.79,0,0,1,.73.74v5.47a.79.79,0,0,1-.73.74Z' />
                <path d='M166,69.23c-11.43,0-17.51-6-17.45-16.9l.12-11.68L148.34,26a.8.8,0,0,1,.74-.74h8.42a.8.8,0,0,1,.74.74l-.31,14.39L158,52.27c0,6.14,2.46,9.52,8,9.52,6,0,8.67-3.38,8.67-9.52V40.59L174.46,26a.8.8,0,0,1,.74-.74h7.5a.8.8,0,0,1,.74.74l-.25,14.63V52.33C183.19,63.27,177.48,69.23,166,69.23Z' />
                <path d='M206.08,69.29c-11.25,0-19-4.67-19.48-14.75a.79.79,0,0,1,.74-.74h8.29a.8.8,0,0,1,.8.74c.68,4.67,2.89,7.75,9.84,7.75,5.59,0,8.66-1.66,8.66-5.72,0-9.71-27.47-2-27.47-19.11,0-8.06,6.27-13.1,17.27-13.1,10,0,16.9,3.88,18.25,12.91a.65.65,0,0,1-.61.74h-8.3a.82.82,0,0,1-.86-.74c-.55-3.75-3.69-5.84-8.85-5.84-4.42,0-7.62,1.54-7.62,5.1,0,9.16,27.6,1.72,27.6,18.75C224.34,64.38,217.33,69.29,206.08,69.29Z' />
                <path d='M332.58,68.31a.79.79,0,0,1-.73-.74l.3-18.74H313.34l.31,18.74a.8.8,0,0,1-.74.74h-8.24a.79.79,0,0,1-.73-.74l.43-20.77L303.94,26a.8.8,0,0,1,.73-.74h8.24a.8.8,0,0,1,.74.74l-.31,15.86h18.81L331.85,26a.79.79,0,0,1,.73-.74h8.24a.8.8,0,0,1,.74.74l-.44,20.78.44,20.77a.8.8,0,0,1-.74.74Z' />
                <path d='M347.91,68.31a.8.8,0,0,1-.74-.74l.43-20.77L347.17,26a.8.8,0,0,1,.74-.74H356a.8.8,0,0,1,.74.74l-.43,20.78.43,20.77a.8.8,0,0,1-.74.74Z' />
                <path d='M382.15,58.72l-4.24-6.39H371.7L372,67.57a.8.8,0,0,1-.74.74H363.1a.8.8,0,0,1-.74-.74l.37-20.77L362.36,26a.8.8,0,0,1,.74-.74h17.33c10.76,0,17,4.55,17,13.53,0,6.33-3.5,10.69-9.58,12.54L398.38,67.7a.39.39,0,0,1-.31.61h-9.4a1.38,1.38,0,0,1-1.11-.61ZM380.8,32.11h-8.91l-.19,13.4h9.35c3.93,0,7.31-1.85,7.31-6.52S385.59,32.11,380.8,32.11Z' />
                <path d='M419.05,69.23c-11.43,0-17.51-6-17.45-16.9l.12-11.68L401.35,26a.8.8,0,0,1,.74-.74h8.42a.8.8,0,0,1,.74.74l-.31,14.39L411,52.27c0,6.14,2.46,9.52,8.05,9.52,6,0,8.67-3.38,8.67-9.52V40.59L427.47,26a.8.8,0,0,1,.74-.74h7.5a.8.8,0,0,1,.74.74l-.25,14.63V52.33C436.2,63.27,430.49,69.23,419.05,69.23Z' />
                <path d='M450.12,52.39V67.57a.8.8,0,0,1-.74.74H442a.79.79,0,0,1-.73-.74l.37-20.77L441.27,26a.8.8,0,0,1,.73-.74h10a1.39,1.39,0,0,1,1.11.62l17.08,30.3v-.74l-.3-12.17V26a.8.8,0,0,1,.73-.74H478a.8.8,0,0,1,.74.74l-.37,20.78.37,20.77a.8.8,0,0,1-.74.74H467.64a1.38,1.38,0,0,1-1.11-.61L449.69,37.64l.12,2.46Z' />
                <path d='M484.74,68.31a.79.79,0,0,1-.73-.74l.43-20.77L484,26a.8.8,0,0,1,.73-.74H499.8c12.36,0,22.81,6.64,22.81,21.83S512,68.31,499.93,68.31Zm8.92-7h6.76C507.36,61.36,513,57.25,513,47s-5.54-14.81-12.79-14.81h-6.51c0,.06-.37,7.25-.37,14.38C493.29,53.93,493.66,61.3,493.66,61.36Z' />
                <path d='M45,45H25.21a.79.79,0,0,0-.74.73V51a.8.8,0,0,0,.74.74H35.72c-.24,6.63-4.11,10.39-10.26,10.39-7.62,0-11.86-5.91-11.86-15.25a24.87,24.87,0,0,1,1-7.13A33.94,33.94,0,0,0,29.09,43.1a20,20,0,0,0,9.24-1.9,7.57,7.57,0,0,0,4.39-6.75,8.08,8.08,0,0,0-2.56-5.92C36.4,24.91,29.52,24.34,25,24.38v0c-.23,0-.44,0-.67,0h-.62v0a20.6,20.6,0,0,0-12.13,4.34,13.46,13.46,0,0,1-3.34-9.28c0-2.81.44-4,1.15-4,.92-.08,1.44,1.47,2.06,2.63,1.07,2,2.8,4.33,5.73,3.95,3.25-.41,3.42-3.61,3.58-6.43.06-1.09-.17-5,.51-5.1s.92,3.89,1.11,5c.5,2.78,1.13,6.24,4.66,5.92,2.46-.22,3.28-2.18,4.64-5.42.53-1.27,1.79-5.93,2.76-5.79s.24,4.72-.14,7.27l7,1.05c.66-4.38,1.48-14.75-5-16.43-3.85-1-6.52,2-8.53,5.62-1.23-3.35-3.35-6.22-7.21-5.77-4.23.49-5.74,4.41-6.36,8.35A6.77,6.77,0,0,0,7.72,8.57c-3.19.83-5.54,4-6.27,8.37A21,21,0,0,0,7.06,34.11,25.16,25.16,0,0,0,3.94,46.8c0,13.34,8.67,22.43,21.21,22.43,13.22,0,20.65-7.68,20.59-23.48A.79.79,0,0,0,45,45Zm-9.74-11.4a1,1,0,0,1,.4.85,3.93,3.93,0,0,1-2.11,1.06c-3.83,1-10.36.5-15.3-1.78a10.24,10.24,0,0,1,6.46-2.3C30.65,31.33,34.11,32.52,35.26,33.62Z' />
                <path d='M274.92,68.49a1.88,1.88,0,0,1-1.23-.55l-1.9-2.4a21.46,21.46,0,0,1-12.36,3.75c-8.29,0-15.74-4.24-15.74-12.47,0-6.77,4.92-10.51,10.21-13.34-2.46-3.14-4.43-6.39-4.43-9.77,0-5.29,4.8-9.41,12.79-9.41,6.64,0,11.92,3.32,12.05,9.41,0,5.16-4.31,8-9,10.63,1.48,1.66,3.14,3.69,5.1,6.09L272.46,53a26.86,26.86,0,0,0,1.29-5.78.8.8,0,0,1,.8-.74h7.07a.65.65,0,0,1,.62.74,30,30,0,0,1-4.49,12.29l6.88,8.48a.31.31,0,0,1-.24.55ZM260.17,63a11.28,11.28,0,0,0,7.44-2.76l-7.87-9.83c-.56-.68-1.11-1.36-1.72-2-3,2-5.23,4.42-5.23,7.93C252.79,61.06,255.87,63,260.17,63Zm-2-29.13c0,1.54.92,3,3.2,5.78,3.13-1.66,5.35-3.38,5.35-5.9s-1.67-4.12-4.43-4.12C259.8,29.65,258.14,31.06,258.14,33.89Z' />
                <path d='M588,12.79,572.11,24.36l7-18.5c-.11.17-8,14.23-16.89,23.92-3.76-3.48-8.92-5.42-15.07-5.42-13.22,0-21.7,8.85-21.7,22.56,0,13.16,8.54,22.31,21.7,22.31s21.75-9.15,21.75-22.31a26.05,26.05,0,0,0-2.18-10.85A185.39,185.39,0,0,0,588,12.79ZM556,35.56c-.76.56-1.51,1.07-2.25,1.51-3.23,1.87-6.74,1.88-8.16.82-2-1.49-1.12-3.84-.92-4.3a3.64,3.64,0,0,1,2-1.75,4.35,4.35,0,0,1,.95-.26A10.67,10.67,0,0,1,556,35.56Zm3.23,11.36c0,9.4-4.3,15.12-12,15.12s-12.11-5.78-12.11-15.12a19.75,19.75,0,0,1,2.3-10.11,10,10,0,0,0,4,6.72,11.7,11.7,0,0,0,7.06,2.13,18,18,0,0,0,8.9-2.49c.5-.3,1-.62,1.52-1A25.7,25.7,0,0,1,559.26,46.92Z' />
              </svg>
              {/* <svg
                id='Layer_1'
                data-name='Layer 1'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 929.99 115.45'
                fill='var(--color-white)'
              >
                <path d='M69.83,19.11c-.27,3.06-.55,6.13-.79,9.19-.11,1.3-.55,1.95-2.05,1.64-2.36-.48-4.76-.8-7.15-1.12-1.19-.16-1.44-.73-1.31-1.86a69.51,69.51,0,0,0,.53-7.21c0-2.16-.26-4.31-.46-6.47a8.37,8.37,0,0,0-.44-1.59A6,6,0,0,0,57,13.08c-1.17,2.74-2.3,5.5-3.36,8.29-.94,2.48-1.67,5.05-2.68,7.5a8.2,8.2,0,0,1-3.72,4.24,7,7,0,0,1-8.65-1.54,16.48,16.48,0,0,1-3.91-7.43c-.1-.37-.24-.72-.36-1.09-.09.4-.19.8-.27,1.21A45.31,45.31,0,0,1,33,29.88c-1.69,5.35-7.08,7-11.55,3.53-2.53-1.93-3.92-4.69-5.25-7.48-.35-.75-.6-1.55-1-2.26a9.29,9.29,0,0,0-1.26-1.42,6.27,6.27,0,0,0-1.18,1.54,26.76,26.76,0,0,0-1.44,5.27C10,39.88,12.89,49.32,21,56.88c1.42,1.32,1.47,1.32,2.53-.21,5.11-7.42,12.25-11.29,21-12.92a38.51,38.51,0,0,1,17.49.68c5.42,1.48,9.84,4.42,12.35,9.7a13.29,13.29,0,0,1-4.35,17,36.14,36.14,0,0,1-13.19,5.69l-.94.25a7.69,7.69,0,0,0,1.05.18H78.77c2.78,0,2.87,0,2.8,2.76-.2,7.56-1.35,14.91-5.27,21.56C71.73,109.4,64.78,113.56,56,114.93a34.47,34.47,0,0,1-18.17-1.72c-10.45-4.08-16.46-12-19.19-22.66a45.54,45.54,0,0,1-.48-20,2.76,2.76,0,0,0-1.34-3.23C3,57.1-2.61,40.45,1.14,23.88A23.6,23.6,0,0,1,3.6,17.66C7.88,10,17.29,9.16,22.92,16a10.65,10.65,0,0,0,1,1c.07-.49.15-1,.22-1.47.49-3.54.89-7.1,2.61-10.33A9,9,0,0,1,37.25.33a7.87,7.87,0,0,1,5,3.47A17.78,17.78,0,0,1,45,12a5.38,5.38,0,0,0,.28.92,8.28,8.28,0,0,0,.52-.79c1-2,1.9-4.07,3.08-6A12.65,12.65,0,0,1,55.17.73c5-1.84,9.53-.15,12.07,4.51a21.79,21.79,0,0,1,2.29,8.61c.17,1.73,0,3.5,0,5.24ZM29.74,81a84.48,84.48,0,0,0,1.44,9c2.64,10.31,9.94,15.94,20.17,15.83A17.41,17.41,0,0,0,68.74,89.33c.15-2.7.09-2.77-2.66-2.78-4.91,0-9.82,0-14.73,0-2.1,0-2.23-.19-2.27-2.31,0-1.58,0-3.16,0-4.74,0-2.19,0-2.13-2.27-2.43q-5.67-.75-11.33-1.64c-1.76-.27-3.49-.67-5.24-1ZM49.15,66.87c3.11-.5,6.27-.77,9.3-1.57a16.78,16.78,0,0,0,5.48-2.81c1.85-1.37,1.92-3.11.22-4.69A14.53,14.53,0,0,0,59.56,55c-3.23-1.23-6.68-1.26-10.11-1.14-1.86.06-3.71-.29-5.6.35a17.64,17.64,0,0,0-9.7,7.8c-1,1.6-.95,1.75,1,2.33A60.17,60.17,0,0,0,49.15,66.87Z' />
                <path d='M846.54,43.45c8.94.23,16.66,2.15,23,7.82a40,40,0,0,1,5.07,5.79c.86,1.15,1.49,1.42,2.85.82A92,92,0,0,0,897.1,45.62,187.5,187.5,0,0,0,921,23c1.59-1.78,1-1.7,2.8-.27s3.41,2.93,5.17,4.31c1.17.92,1.35,1.64.23,2.78-3.91,4-7.63,8.14-11.61,12a140.17,140.17,0,0,1-27,21.25c-2.94,1.71-6,3.2-9.12,4.61-1.29.59-1.81.93-1.53,2.65,1.19,7.26,1.7,14.59-.89,21.68-4.81,13.21-14.18,21-28.19,22.89a33.27,33.27,0,0,1-36.82-27.36c-1.76-10.7-.66-21.12,5.49-30.44,5.24-7.93,12.89-12,22.17-13.26C843.53,43.64,845.36,43.55,846.54,43.45Zm21.86,35.6c-.14-1.66-.33-3.81-.5-6-.11-1.29-.73-1.76-2.09-1.77a50.26,50.26,0,0,1-9.44-.48c-8.25-1.63-14.9-5.61-18.2-13.87-.62-1.58-.69-1.59-2.22-.61a19.77,19.77,0,0,0-7.9,10c-2.52,6.63-2.76,13.47-1.67,20.4a24.84,24.84,0,0,0,3.28,9.34c4.45,7.21,11.13,9.86,19.34,9.33,7.22-.46,12.63-3.73,16-10.16C867.67,90.34,868.21,85,868.4,79.05ZM861.61,61l1.1-.34a7.73,7.73,0,0,0-.66-1,17.46,17.46,0,0,0-10.73-5.79c-1-.14-1.92-.13-2.89-.19.53.93,1,1.86,1.59,2.76a2.64,2.64,0,0,0,.73.68A21.5,21.5,0,0,0,861.61,61Z' />
                <path d='M739.68,79.12v32c0,2.48,0,2.56-2.46,2.59-3.17,0-6.34-.1-9.5,0a4.58,4.58,0,0,1-4.62-2.6q-13.41-22.65-27-45.22c-.73-1.21-1.42-2.44-2.17-3.64-.15-.23-.49-.35-.74-.51a5.63,5.63,0,0,0-.14.69c0,.67,0,1.33,0,2q0,23.13,0,46.24c0,2.91-.06,3-2.89,3-2.17,0-4.33,0-6.5,0-2.36,0-2.59-.23-2.56-2.58.1-5.92.36-11.83.34-17.74,0-14.74-.19-29.48-.31-44.23,0-.41,0-.83,0-1.25,0-2.94,0-3,2.86-3,3.08,0,6.17.09,9.25,0A3.8,3.8,0,0,1,697.06,47Q707,63.73,717,80.4q4.82,8,9.62,16.07a7.28,7.28,0,0,0,.77.91,4.69,4.69,0,0,0,.37-1.27q0-16,0-32c0-5.5,0-11,0-16.5,0-2.55.19-2.71,2.68-2.73s5,0,7.49,0c1.31,0,1.79.5,1.77,1.77q-.22,14.74-.39,29.48c0,1,0,2,0,3Z' />
                <path d='M438.51,114c-1.66,0-3.33-.06-5,0a3.32,3.32,0,0,1-3-1.42c-.83-1-1.74-2-2.49-3.13s-1.47-1.18-2.59-.34A29.45,29.45,0,0,1,413,114.63c-7.95,1.5-15.58.66-22.6-3.65-10.27-6.3-10.68-20-4.1-27.65a40.38,40.38,0,0,1,10.09-8.08c1.73-1,1.75-1.08.55-2.58a27.52,27.52,0,0,1-5.56-12.36,13.16,13.16,0,0,1,4.31-12.83c6.69-6.19,22.07-6.81,29.18,1.33A13.5,13.5,0,0,1,424.8,67a33.32,33.32,0,0,1-8.52,6.45,13.49,13.49,0,0,1-1.53.85c-1.15.47-1.28,1-.45,2,3.91,4.55,7.76,9.14,11.65,13.7,1.05,1.23,1.25,1.22,1.65-.26.86-3.19,1.59-6.43,2.3-9.66.46-2.08.41-2.11,2.57-2.13q3,0,6,0c2.34,0,2.44.14,2.24,2.39a42.32,42.32,0,0,1-4.79,15.87c-1.84,3.51-1.82,3.48.7,6.49,2.67,3.2,5.29,6.43,7.9,9.67a6.12,6.12,0,0,1,.61,1.29,6.83,6.83,0,0,1-1.37.33C442,114,440.26,114,438.51,114ZM394,94c0,5.79,2.27,9.5,6.66,11a20,20,0,0,0,19.11-2.9c1-.71,1.05-1.25.28-2.18-2.49-3-4.89-6.09-7.37-9.1s-5.21-6.25-7.81-9.38c-.64-.77-1.2-1.2-2.28-.53C397.36,84.16,394.4,88.76,394,94Zm16.06-43.82c-5.88-.52-9.05,5-7.62,9.77,1,3.2,3,5.72,5,8.21a1.49,1.49,0,0,0,1.44.07,58.62,58.62,0,0,0,6.67-4.74,7.45,7.45,0,0,0,2.07-8.85C416.29,51.28,413.55,50.08,410.06,50.22Z' />
                <path d='M560.12,80.76c-.19-11.82-.37-22.22-.53-32.63,0-3.23,0-3.24,3.08-3.24,8.17,0,16.33-.06,24.49,0a35.21,35.21,0,0,1,14.33,2.82c6.9,3.11,10.36,8.59,11.06,15.94.47,5-.29,9.78-3.2,14.05a19.76,19.76,0,0,1-10.83,8c-1.68.51-1.7.57-.74,2.08q7.34,11.59,14.69,23.17a3.74,3.74,0,0,1,.27.42c.28.67.54,1.34.81,2a12.26,12.26,0,0,1-2.29.47c-2.66.06-5.34-.13-8,.07-2.26.16-3.47-.84-4.55-2.67-4.46-7.51-9.05-14.93-13.52-22.42A3.13,3.13,0,0,0,582,87c-2.91.11-5.83.08-8.74,0-1.29,0-1.74.39-1.7,1.72.19,6.41.3,12.82.43,19.23,0,1.33,0,2.66.05,4s-.61,1.94-2,1.92c-2.83,0-5.67-.06-8.5,0-1.51,0-2.07-.6-2.05-2.08.09-4.42.06-8.83.14-13.24C559.82,92.17,560,85.76,560.12,80.76ZM572,66h-.41c0,3.25,0,6.49,0,9.73,0,2.08,0,2.11,2,2.12,4.74,0,9.49.09,14.22,0a23.44,23.44,0,0,0,5.63-1,9.34,9.34,0,0,0,6.87-6.61c2-7.16-.59-15.22-10.67-15.85-5.3-.33-10.64-.06-16-.15-1.37,0-1.68.56-1.66,1.78C572,59.37,572,62.7,572,66Z' />
                <path d='M749.28,79c-.2-10.26-.41-20.67-.6-31.08,0-3,0-3,3.09-3,7.5,0,15-.07,22.49.06a39.9,39.9,0,0,1,16,3.45A28.45,28.45,0,0,1,806.14,66a43.16,43.16,0,0,1,.7,24.61c-3,11.52-10.67,18.55-22,21.61a50.15,50.15,0,0,1-11.33,1.57c-7.4.24-14.83.08-22.24.06-2.51,0-2.66-.15-2.62-2.74C748.87,100.47,749.07,89.81,749.28,79Zm11.41-.2h.22c0,2.83,0,5.66,0,8.48.08,5,.24,10,.3,15,0,1.4.33,2.13,2,2.08,3.65-.11,7.33.1,11-.07,8.11-.39,14.66-3.73,18.59-11,4.07-7.55,4.28-15.61,2-23.72-2.4-8.56-8-14-16.88-15.16-4.83-.64-9.78-.28-14.68-.49-1.59-.07-2,.55-2,2C761.06,63.5,760.86,71.15,760.69,78.8Z' />
                <path d='M330.26,115.38a33.26,33.26,0,0,1-15.58-2.71C306.11,108.92,302,102.06,301.13,93c-.18-1.89.11-2.16,2.06-2.19,2.83,0,5.66,0,8.49,0a1.85,1.85,0,0,1,2.16,1.82,26.73,26.73,0,0,0,1,4.35c1.93,5.65,6.32,8.29,11.9,8.77a45.92,45.92,0,0,0,11.17-.44c5.69-.92,8.65-4.63,8.69-9.87a7.22,7.22,0,0,0-4.26-7.06,44.51,44.51,0,0,0-8.16-2.9c-5.4-1.34-10.9-2.27-16.29-3.6a23.63,23.63,0,0,1-10.56-5.37,14.92,14.92,0,0,1-4.72-9.38c-1.2-9.07,2.94-16.91,11.4-20.39a37.8,37.8,0,0,1,31.45.6c6.93,3.23,10.15,9.26,11.35,16.57.2,1.26-.4,1.75-1.45,1.77-3.17,0-6.33,0-9.5,0-1.06,0-1.56-.63-1.81-1.69-1.43-6.21-4.73-9.12-11-10.12a25.51,25.51,0,0,0-11.85.62c-3.55,1.1-6.2,3.2-6.67,7.21-.45,3.78.59,6.22,4.07,7.71a61.36,61.36,0,0,0,9.72,3c5.24,1.28,10.58,2.15,15.78,3.56,4.72,1.28,9,3.54,11.86,7.77,6.52,9.65,1.75,24.59-9.36,29.15C342.84,114.39,339.31,115.69,330.26,115.38Z' />
                <path d='M527.31,79.41q.26,16,.52,32c0,2-.2,2.28-2.27,2.31-2.58,0-5.17,0-7.75,0-2.29,0-2.57-.36-2.53-2.62q.21-13.6.39-27.22c0-2.4,0-2.42-2.42-2.42q-14.25,0-28.49,0c-2.29,0-2.3,0-2.26,2.33q.22,13.49.49,27c.05,2.76-.12,3-2.94,3-2.5,0-5,0-7.49,0-1.93,0-2.09-.24-2.06-2.22.13-9.08.39-18.16.39-27.23,0-10.07-.21-20.15-.33-30.22,0-2.25-.06-4.5-.07-6.75s.08-2.35,2.41-2.37,4.83,0,7.25,0c2.24,0,2.43.18,2.39,2.44-.12,7.4-.29,14.81-.44,22.22,0,.42,0,.84,0,1.25-.14,1.23.44,1.55,1.59,1.55q15-.06,30,0c1.45,0,1.59-.66,1.57-1.85-.11-7.66-.17-15.32-.23-23,0-2.62,0-2.63,2.64-2.64s5.33,0,8,0c2.08,0,2.26.26,2.22,2.31Q527.57,63.31,527.31,79.41Z' />
                <path d='M112.85,95.05H102.36c-2.92,0-2.92,0-3.79,2.68-1.48,4.57-2.95,9.15-4.45,13.73-.8,2.45-.82,2.46-3.25,2.47s-4.83,0-7.24,0-2.4-.1-1.68-2.18q3.9-11.3,7.82-22.58Q96.93,68.6,104.11,48c1.09-3.13,1-3.14,4.37-3.14h10c2.94,0,3,0,3.94,2.73q6.87,20.15,13.74,40.28c2.55,7.46,5.14,14.9,7.7,22.36.27.78.53,1.57.76,2.36s0,1.3-.91,1.3c-3.25,0-6.49,0-9.74,0-1.32,0-1.59-1-1.91-1.94-1.63-4.88-3.25-9.76-4.91-14.63-.74-2.21-.2-2.29-3-2.3Zm0-10.13c2.91,0,5.82,0,8.73,0,1.34,0,1.47-.37,1.06-1.59-3.16-9.35-6.25-18.71-9.37-28.07-.13-.37-.34-.71-.5-1.06a4.22,4.22,0,0,0-.66,1c-1.43,4.5-2.83,9-4.25,13.51-1.5,4.74-3,9.47-4.51,14.21-.62,1.94-.59,2,1.52,2C107.57,84.93,110.23,84.92,112.89,84.92Z' />
                <path d='M661.58,68.59q0-10.5,0-21c0-2.63.07-2.69,2.62-2.71,2.25,0,4.5,0,6.75,0,1.39,0,2,.56,1.95,1.92,0,.5,0,1,0,1.5,0,13.24.09,26.48,0,39.72a43.49,43.49,0,0,1-1.3,10.1C669.42,106.57,664,112,655.43,114c-5.78,1.32-11.64,1.85-17.53.47a24.13,24.13,0,0,1-18-26.34,17.23,17.23,0,0,0,.08-2.49q-.19-19.11-.41-38.21c0-2.64,0-2.68,2.6-2.7s5.16,0,7.74,0c2.18,0,2.23.08,2.21,2.28-.11,13.24-.3,26.47-.33,39.71a41.66,41.66,0,0,0,.85,8.41c1.23,6,5.51,9.5,11.61,9.76a28.32,28.32,0,0,0,6-.19c6-1.08,9.82-5,10.81-11.18a41.48,41.48,0,0,0,.51-6.2C661.62,81.08,661.58,74.83,661.58,68.59Z' />
                <path d='M241.68,70.22q0-11.25,0-22.5c0-2.66.18-2.81,2.85-2.83H251c2.57,0,2.6,0,2.6,2.71,0,12.75,0,25.5,0,38.25a56.08,56.08,0,0,0,.47,7.21c.62,4.58,2.45,8.47,6.89,10.59,5.92,2.83,14.6,1.5,18.61-2.86,3-3.27,3.78-7.37,3.89-11.57.16-6.49.06-13,.07-19.49q0-11.13,0-22.25c0-2.49.08-2.56,2.48-2.59,2,0,4,0,6,0,2.52,0,2.52,0,2.55,2.45v.25q0,20.75,0,41.49a32,32,0,0,1-2.37,12.41A20,20,0,0,1,277.36,114c-6.44,1.41-13,2.15-19.44,0a23.94,23.94,0,0,1-16.35-22.25c-.08-7.16,0-14.33,0-21.49Z' />
                <path d='M198.77,79.5,198.11,48c0-.58,0-1.17,0-1.75a1.3,1.3,0,0,1,1.42-1.39H209c1.36,0,1.63.82,1.61,2,0,4.08,0,8.16-.09,12.24q-.19,21-.41,42v1c0,2.2,0,2.22,2.17,2.23,5.41,0,10.82,0,16.24,0,3.64,0,7.29-.25,10.94-.27a1.69,1.69,0,0,1,1.16,1.16c.08,2.41,0,4.83,0,7.24a1.53,1.53,0,0,1-1.63,1.76c-.49,0-1,0-1.49,0H201.24c-.33,0-.66,0-1,0-1.89-.1-2.16-.32-2.13-2.23.06-4.33.26-8.65.3-13,.05-6.49,0-13,0-19.48Z' />
                <path d='M150.59,78.66c-.17-10-.35-20.6-.51-31.18,0-2.73,0-2.74,2.79-2.76,2.42,0,4.83,0,7.25,0,2.21,0,2.43.26,2.4,2.47-.14,9.49-.41,19-.42,28.48,0,8.91.29,17.83.37,26.74,0,1.59.63,1.92,2.09,1.91,8.33-.06,16.67,0,25,0,2.8,0,2.81,0,2.84,2.73,0,1.75,0,3.5,0,5.25a1.46,1.46,0,0,1-1.73,1.62c-1.66,0-3.33,0-5,0q-16.38,0-32.75,0c-2.85,0-2.87,0-2.83-2.94C150.24,100.37,150.41,89.79,150.59,78.66Z' />
                <path d='M537.94,79.41c-.21-10.06-.37-20.12-.65-30.18-.13-4.34-.24-4.34,4-4.34,2,0,4,0,6,0,2.26,0,2.42.17,2.38,2.44-.12,7-.38,14-.38,21,0,11.06.19,22.13.29,33.19,0,3.32.07,6.65.09,10,0,2.11-.16,2.3-2.23,2.33-2.49,0-5,0-7.49,0s-2.71-.15-2.66-2.69q.32-15.48.68-30.94c0-.25,0-.5,0-.75Z' />
              </svg> */}
            </div>
          </LogoLink>
          <MobileMenuList
            style={{ marginTop: '2rem', textTransform: 'uppercase' }}
          >
            {menuItems.map((item, i) => (
              <Link
                key={i}
                to={item.path}
                style={{ color: 'inherit', textDecoration: 'none' }}
                onClick={() => setIsOpen(false)}
              >
                <li key={i}>{item.name}</li>
              </Link>
            ))}
          </MobileMenuList>
        </div>
      </MobileMenu>
    </>
  );
}
